import axios from 'axios';
export function cartAdd (itemType, cartItem, email) {
  const data = {
    email: `${email}`,
    dataFields: {
      product: `${itemType}`,
      session: `${cartItem}`,
    },
    eventName: 'cart_add',
  };
  iterableEvent(data)
    .then(result => console.log('(1) Outside result:', result))
    .catch(error => console.error('(1) Outside error:', error));
}

export function checkoutComplete (oldCart, $cookies) {
  const data = {
    email: `${oldCart.account.email}`,
    dataFields: {
      product: `${oldCart.items[0].sessionId}`,
    },
    eventName: 'checkout_complete',
  };
  iterableEvent(data)
    .then(result => console.log('(1) Outside result:', result))
    .catch(error => console.error('(1) Outside error:', error));
  const newItems = [];
  oldCart.items.forEach(item => newItems.push({
    id: item.id,
    quantity: oldCart.itemCount,
    price: item.price,
    name: item.title,
  }));
  const setEmail = $cookies.get('iterableEndUserId') ? $cookies.get('iterableEndUserId') : `${oldCart.account.email}`;
  const setCampaignId = $cookies.get('iterableEmailCampaignId') ? $cookies.get('iterableEmailCampaignId') : 0;
  const setTemplateId = $cookies.get('iterableTemplateId') ? $cookies.get('iterableTemplateId') : 0;
  const trackPurchaseData = {
    user: {
      email: setEmail,
    },
    items: newItems,
    total: Number(`${oldCart.total}`),
    campaignId: setCampaignId,
    templateId: setTemplateId,
  };
  iterableTrackPurchase(trackPurchaseData)
    .then(result => console.log('(2) iterableTrackPurchase result:', result))
    .catch(error => console.error('(2) iterableTrackPurchase error:', error));
}

export function newSubOrder (data) {
  iterableEvent(data)
    .then((result) => {
      console.log('(1) Outside result:', result);
    })
    .catch(error => console.error('(1) Outside error:', error));
}

export function orderCreated (email) {
  const data = {
    email: `${email}`,
    eventName: 'order_created',
  };
  iterableEvent(data)
    .then(result => console.log('(1) order_created result:', result))
    .catch(error => console.error('(1) order_created error:', error));
}

export function subscriptionCreated (data) {
  iterableEvent(data)
    .then(result => console.log('(1) subscription_created result:', result))
    .catch(error => console.error('(1) subscription_created error:', error));
}

export function forgotPassword (email) {
  const data = {
    email: `${email}`,
    eventName: 'forgot_password',
  };
  iterableEvent(data)
    .then(result => console.log('(1) forgot_password result:', result))
    .catch(error => console.error('(1) forgot_password error:', error));
}

export function forgotUsername (email) {
  const data = {
    email: `${email}`,
    eventName: 'forgot_username',
  };
  iterableEvent(data)
    .then(result => console.log('(1) forgot_username result:', result))
    .catch(error => console.error('(1) forgot_username error:', error));
}

export async function emailSignUp (event, email, profession) {
  const data = {
    email: `${email}`,
    eventName: `${event}`,
    profession: `${profession}`,
  };
  const iterableResponse = await iterableEvent(data)
    .then((result) => {
      console.log('(1) email_signup result:', result);
      return result;
    })
    .catch((error) => {
      console.error('(1) email_signup error:', error);
      return error;
    });
  return iterableResponse;
}

export function iterableEvent (data) {
  const request = axios.post(`https://api.iterable.com/api/events/track`, data, { headers: { 'api_key': 'f50c81b2715046a7bf4ec9553e14a62f' } });
  request
    .then(result => console.log('(1) Inside result:', result)).catch(error => console.error('(1) Inside error:', error));
  return request;
}

function iterableTrackPurchase (trackPurchaseData) {
  const request = axios.post(`https://api.iterable.com/api/commerce/trackPurchase`, trackPurchaseData, { headers: { 'api_key': 'f50c81b2715046a7bf4ec9553e14a62f' } });
  request
    .then(result => console.log('(2) Inside iterableTrackPurchase result:', result)).catch(error => console.error('(2) Inside iterableTrackPurchase error:', error));
  return request;
}

export function registerUserBrowserToken (browserTokenAndEmail) {
  const request = axios.post(`https://api.iterable.com/api/users/registerBrowserToken`, browserTokenAndEmail, { headers: { 'api_key': 'f50c81b2715046a7bf4ec9553e14a62f' } });
  request
    .then(result => console.log('Successfully tracked browser token:', result)).catch(error => console.error('Tracking browser token failed, error:', error));
  return request;
}
