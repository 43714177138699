export const state = () => ({
  impersonating: false,
});

export const getters = {
  isAuthenticated (state) {
    return state.auth.loggedIn;
  },

  loggedInUser (state) {
    return state.auth.user;
  },
  impersonating: state => state.impersonating,
};

export const actions = {
  setImpersonate ({ commit }, impersonate) {
    commit('setImpersonate', impersonate);
  },
// initial action called during the SSR process
// nuxtServerInit ({ dispatch }) {
// setup the base search data
//     dispatch('search/loadCookieData');
//     dispatch('cart/loadCookieData');
//   },
};
export const mutations = {
  setImpersonate (state, impersonate) {
    state.impersonating = impersonate;
  },
};
