//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import ProfileProfessions from '@/components/account/ProfileProfessions';
import { emailSignUp, } from '@/services/iterable';
import { gtmEmailSignUp, } from '@/services/gtmManager';
export default {
  components: { ProfileProfessions },
  props: {
    source: {
      type: String,
      required: false,
      default: 'main',
    },
  },
  data () {
    return {
      email: '',
      name: '',
      zipcode: '',
      profession: {
        type: Object,
        default: undefined,
      },
      professionString: {
        type: String,
        default: '',
      },
      missingFields: {
        type: String,
        default: () => '',
      },
    };
  },
  mounted () {
    document.onreadystatechange = () => {
      if (document.readyState) {
        if (this.$route.query.src === 'optin_monster') {
          this.name = this.$route.query.name;
          this.email = this.$route.query.email;
          this.zipcode = this.$route.query.zipcode;
          this.professionString = this.$route.query.profession;
          this.$nextTick(() => {
            this.handleSubmit();
          });
        }
      }
    };
  },
  methods: {
    async handleSubmit () {
      this.missingFields = '';
      if (this.$route.query.src !== 'optin_monster') {
        if (this.profession === undefined || this.profession.id === undefined) {
          this.missingFields = 'You must choose a profession!';
          return;
        } else {
          this.professionString = this.profession.id;
        }
      }
      // eslint-disable-next-line no-undef
      await EventTracker.emailSignup.track({
        email: this.email,
        zipcode: this.zipcode,
        first_name: this.name,
        profession: this.professionString,
        source: this.source,
        type: 'marketing',
      })
        .then(async (response) => {
          gtmEmailSignUp(this.$gtm, this.email, this.professionString);
          console.log('profession', this.professionString);
          if (this.$route.query.custom_event) {
            await emailSignUp(this.$route.query.custom_event, this.$route.query.email, this.professionString).then((response) => {
              console.log('email signup finished');
              console.log(response);
              if (this.$route.query.src === 'optin_monster' && this.$route.query.redirect === 'true' && this.$route.query.thankyoupage) {
                console.log('thankyouupage redirect happening');
                window.location.replace(this.$route.query.thankyoupage);
              } else {
                console.log('redirect happening');
                this.$router.push('/courses/email-signup-thank-you');
              }
            });
          } else {
            await this.$router.push('/courses/email-signup-thank-you');
            console.log('else redirect happening');
          }
          console.log(response);
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
};
