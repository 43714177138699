//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  layout: 'empty',
  props: {
    error: {
      type: Object,
      default: null,
    },
  },
  data () {
    return {
      pageNotFound: 'Sorry, we can\'t find that page and it might have recently moved.',
      otherError: 'We\'re sorry, the page you\'re on caused an error. \nPlease try again or contact us online or at (800) 433-9570 (Mon-Fri 7am-6pm Central).',
    };
  },
  head () {
    console.log(`Error thrown: ${JSON.stringify(this.error)}`);
    // this is likely already being captured elsewhere and causing duplication
    // even if it isn't this will capture every single 404 that happens
    // it might just be crawlers/hackers trying random urls
    const title =
      this.error.statusCode === 404 ? this.pageNotFound : this.otherError;
    return {
      title,
    };
  },

  async mounted () {
    console.log('error layout mounted: ', this.error);
    if (this.error.statusCode === 401) {
      await this.$auth.logout();
      await this.$router.push({ name: 'login', query: { authIssue: true } });
    }
  },
};
