export default function (to, from, savedPosition) {
  if (to.fullPath.startsWith('/subscription/') || to.fullPath.startsWith('/group-sales/')) {
    return new Promise((resolve, reject) => {
      if (to.hash && to.hash !== '') {
        setTimeout(() => {
          resolve({
            selector: to.hash,
            behavior: 'smooth',
          });
        }, 10);
      } else {
        window.scrollTo(0, 0);
      }
    });
  } else if (to.fullPath.startsWith('/course/')) {
    if (to.hash === '' || !from.fullPath.startsWith('/course/')) {
      return { x: 0, y: 0 };
    }
  } else if (!to.fullPath.startsWith('/course/')) {
    return { x: 0, y: 0 };
  } else {
    console.log('scroll hit else');
    // return
  }
}
