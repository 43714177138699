

export default async function (session, firebase) {
  // Can only be initiated on client side
  if (!process.client) {
    return
  }

  await import('firebase/compat/messaging')

  if (firebase.messaging.isSupported()) {
    const messagingService = session.messaging()

    messagingService.getToken({vapidKey: "BPxOSNZG5I82MgpL8aPwR-kF13JPm8AEGoWL5L3EzMoVw0MzasXVhNmn4zLhfkhAcLvgRlLk9cHqK3Qv4ZKokE4"})

    return messagingService
  }
}
