//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import professions from '@/data/profileProfession.json';

export default {
  name: 'ProfileProfessions',
  props: {
    label: {
      type: String,
      default: 'Profession',
    },
    value: {
      type: Object,
      default: () => ({}),
    },
    variant: {
      type: String,
      default: 'signup',
    },
    labelKey: {
      type: String,
      default: 'name',
    },
    valueKey: {
      type: String,
      default: 'id',
    },
    required: {
      type: Boolean,
      default: false,
    },
    elementId: {
      type: String,
      default: 'account-professions-select',
    },
  },
  data () {
    return {
      professions: Object.values(professions),
      selectedOption: this.value[this.valueKey],
    };
  },
  watch: {
    value (newProfession) {
      this.selectedOption = newProfession[this.valueKey];
    },
  },
  mounted () {
    this.$nextTick(() => {
      this.setFirstOptionDisabled();
    });
  },
  // This is how the v-model works when using nested components.
  // This is not always needed, but probably if a nested `v-model` is used.
  methods: {
    setFirstOptionDisabled () {
      const selectElement = document.getElementById(this.elementId);
      const optionElement = selectElement.options[0];
      optionElement.setAttribute('disabled', 'disabled');
    },
    setSelected (newValue) {
      this.$emit('input', getProfessionObject(newValue));
    },
  },
};

function getProfessionObject (newProfession) {
  const isString = typeof newProfession === 'string';
  const selectedState = Object.values(professions).find((profession) => {
    if (isString) {
      return profession.id.toString() === newProfession || profession.name === newProfession;
    } else {
      return profession.id === newProfession;
    }
  });
  return selectedState && Object.freeze({
    ...selectedState,
    type: 'profession',
  });
}
