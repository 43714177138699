//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapActions, mapGetters, } from 'vuex';

export default {
  name: 'SearchNav',
  props: {
    variant: {
      type: String,
      default: 'nav',
    },
  },
  computed: {
    ...mapGetters('search', ['searchText']),
  },
  methods: {
    ...mapActions('search', [
      'updateSearchText', 'applyFilters', 'resetFiltersForSearchText',
    ]),
    search () {
      if (this.searchText.length > 0) {
        this.resetFiltersForSearchText();
      }
      if (this.$nuxt.$route.path.match('^/courses')) {
        // apply the search filters
        this.applyFilters();
      } else {
        // we only want to go to `/courses` when there is search text
        if (!this.searchText) {
          return;
        }
        // go to the search page and do actual searching!?!
        this.$router.push('/courses');
      }
    },
    onClickButton () {
      this.$emit('clicked');
    },
  },
};
