//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapActions, } from 'vuex';

export default {
  name: 'VirtualConference',
  head () {
    return {
      script: [
        {
          hid: 'trustpilot',
          src: '//widget.trustpilot.com/bootstrap/v5/tp.widget.bootstrap.min.js',
          defer: true,
          // Changed after script load
        },
      ],
    };
  },
  computed: {
    isUpgradeOffer () {
      if (this.$auth.loggedIn) {
        if (this.$auth.user.subscription_type.includes('core100' || 'subonline')) {
          return true;
        } else {
          return false;
        }
      } else if (this.$route.query.upgradeoffer === '1') {
        return true;
      } else {
        return false;
      }
    },
  },
  mounted () {
    this.initializeCart();
  },
  methods: {
    ...mapActions('cart', ['initializeCart']),
  },
};
