// cart
export function eecAdd ($gtm, page, productList) {
  $gtm.push({
    event: 'eec.add',
    ecommerce: {
      add: {
        actionField: {
          list: page,
        },
        products: productList,
      },
    },
  });
}
export function eecPurchase ($gtm, action, productsList) {
  $gtm.push({
    event: 'eec.purchase',
    ecommerce: {
      currencyCode: 'USD',
      purchase: {
        actionField: action,
        products: productsList,
        // [{
        //   id: item.sessionId,
        //   name: item.title,
        //   category: itemType,
        //   variant: item.productType,
        //   brand: 'Summit',
        //   quantity: 1,
        // }],
      },
    },
  });
}
export function eecRemove ($gtm, productList) {
  $gtm.push({
    event: 'eec.remove',
    ecommerce: {
      remove: {
        actionField: {
          list: 'Cart',
        },
        products: productList,
      },
    },
  });
}
export function eCommerceNull ($gtm) {
  $gtm.push({ ecommerce: null });
}
export function addHep ($gtm) {
  $gtm.push({
    event: 'add-hep',

  });
}

// checkout
export function checkoutStep1 ($gtm, productsList, $auth) {
  $gtm.push({
    event: 'eec.checkout',
    ecommerce: {
      checkout: {
        actionField: {
          step: 1,
          option: $auth.loggedIn,
        },
      },
      products: productsList,
    },
  });
}
export function checkoutStep2 ($gtm) {
  $gtm.push({
    event: 'eec.checkout',
    ecommerce: {
      checkout: {
        actionField: {
          step: 2,
        },
      },
    },
  });
}
export function checkoutStep3 ($gtm) {
  $gtm.push({
    event: 'eec.checkout',
    ecommerce: {
      checkout: {
        actionField: {
          step: 3,
        },
      },
    },
  });
}
export function checkoutOptionStep2 ($gtm) {
  $gtm.push({
    event: 'eec.checkout_option',
    ecommerce: {
      checkout_option: {
        actionField: {
          step: 2,
          option: 'Separate Billing Address',
        },
      },
    },
  });
}

// experiments
export function experimentEvent ($gtm, experimentName, variant) {
  $gtm.push({
    event: `${experimentName}-experiment-${variant}`,
  });
}

// custom dimension
export function setCustomDimensionSubscriptionType ($gtm, $auth) {
  $gtm.push({
    event: 'setCustomDimension_subscriptionType',
    subscriptionType: $auth.user.subscription_type,
  });
}
export function setCustomDimensionCustomerType ($gtm, customer) {
  $gtm.push({
    event: 'setCustomDimension_customerType',
    customerType: customer,
  });
}
export function setCustomDimensionProfession ($gtm, profession) {
  $gtm.push({
    event: 'setCustomDimension_profession',
    profession,
  });
}

// details
export function eecDetail ($gtm, title, productList) {
  $gtm.push({
    event: 'eec.detail',
    ecommerce: {
      detail: {
        actionField: {
          list: title,
        },
        products: productList,
      },
    },
  });
}

// course details
export function eecCourseDetail ($gtm, pageUrl, Id) {
  $gtm.push({
    event: 'eec.courseDetail',
    url: pageUrl,
    courseId: Id,
    message: 'no sessions found',
  });
}

// impressions
export function eecImpressionView ($gtm, impressions) {
  $gtm.push({
    event: 'eec.impressionView',
    ecommerce: {
      impressions,
    },
  });
}

// login
export function gtmLogin ($gtm, $auth) {
  $gtm.push({
    event: 'login',
    type: $auth.user.subscription_type,
    b2bAdmin: $auth.user.is_admin,
    user_id: $auth.user.person_id,
  });
}

// subscription page
export function upgradeSubscription ($gtm, data) {
  $gtm.push({
    event: 'upgrade-subscription',
    upgrade_log: data,
  });
}
export function createdSubscription ($gtm, data) {
  $gtm.push({
    event: 'new-subscription',
    new_subscription: data,
  });
}
export function createAutoRenew ($gtm, data) {
  $gtm.push({
    event: 'auto-renew',
    new_subscription: data,
  });
}
export function cancelledSub ($gtm, data) {
  $gtm.push({
    event: 'disable-auto-renew-hep',
    cancelled_subscription: data,
  });
}

// free course
export function freeCourseSignup ($gtm, data) {
  $gtm.push({
    event: 'signup-free-course',
    data: { data },
  });
}
// email signup
export function gtmEmailSignUp ($gtm, email, profession) {
  $gtm.push({
    event: 'email-signup',
    email,
    profession,
  });
}
// client signup
export function clientSignUp ($gtm, data) {
  $gtm.push({
    event: 'client-signup',
    data: { data },
  });
}
// navigation
export function gtmSearch ($gtm, text) {
  $gtm.push({
    event: 'search',
    search_text: text,
  });
}
export function gtmCartConfirmation ($gtm, info) {
  $gtm.push({
    event: 'cart-confirmation',
    data: { info },
  });
}
// login
export function navClick ($gtm, eventName, linkClick, linkId, linkClass, linkUrl) {
  $gtm.push({
    event: eventName,
    link_click: linkClick,
    link_id: linkId,
    link_class: linkClass,
    link_url: linkUrl,
  });
}
