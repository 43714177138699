//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: 'Icon',
  props: {
    name: {
      type: [
        Array,
        String,
      ],
      required: true,
    },
  },
};
