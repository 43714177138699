export default {

  exists () {
    return typeof window === 'undefined';
  },

  get (key, defaultValue) {
    if (this.exists()) {
      return defaultValue;
    }
    const value = localStorage.getItem(key);
    return (value && typeof value === 'string' ? JSON.parse(value) : defaultValue) || defaultValue;
  },

  set (key, value) {
    if (!this.exists()) {
      localStorage.setItem(key, JSON.stringify(value));
      console.log('setItem: ' + localStorage.getItem(key));
    }
  },

  remove (key) {
    if (!this.exists) {
      return;
    }
    localStorage.removeItem(key);
  },
  check (key) {
    return this.get(key, undefined);
  },

  clear () {
    window.localStorage.clear();
  },
};
